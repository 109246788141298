<template>
  <picture class="overflow-hidden d-block">
    <source
      v-for="(f, i) in formatExtra"
      :key="i"
      :data-srcset="getFallbackImage(f)"
      :type="`image/${f}`"
    >
    <img
      :class="imageClass"
      :src="typeof url !== 'undefined' && !!url.length ? $getSrcUrl(cleanedProps, 10) : placeholder"
      :data-src="!responsive && typeof url !== 'undefined' && !!url.length ? srcUrl : null"
      :data-srcset="responsive && typeof url !== 'undefined' && !!url.length ? srcSet : null"
      class="js-lazyload blur-up"
      :alt="cleanedProps.altText"
      :data-sizes="responsive ? 'auto' : ''"
      :width="width"
      :height="height"
      loading="lazy"
    >
  </picture>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    url: {
      type: String,
      required: true,
      default: ''
    },
    altText: {
      type: String,
      default: ''
    },
    cropAlias: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 0,
      required: true
    },
    height: {
      type: Number,
      default: 0,
      required: true
    },
    center: {
      type: [ String, Object ],
      default: null
    },
    quality: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: 'webp'
    },
    formatExtra: {
      type: Array,
      default: () => ['webp'] // Example: ['webp']
    },
    includeCdn: {
      type: Boolean,
      default: true
    },
    imageClass: {
      type: String,
      default: null
    },
    furtherOptions: {
      type: String,
      default: null
    },
    preload: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    placeholder () {
      let color = 'ffffff';
      return `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20${ this.width }%20${ this.height }%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23${color}%22%20%2F%3E%3C%2Fsvg%3E`
    },
    srcUrl () {
      return this.$getSrcUrl(this.cleanedProps)
    },
    srcSet () {
      return this.$getSrcSet(this.cleanedProps)
    },
    cleanedProps () {
      const props = {};
      const excludedProps = [
        'imageClass',
        'responsive',
        'formats'
      ]
      this.$options._propKeys.forEach(propKey => {
        if (
          (this.$isNotNullOrUndefined(this[propKey]) &&
           !excludedProps.includes(propKey)) ||
          (this.$isNotNullOrUndefined(this[propKey]) &&
            typeof this[propKey] === 'number' &&
            this[propKey] > 0)
        ) {
          props[propKey] = this[propKey];
        }
      });
      // remove when api returns images
      props['includeCdn'] = this.includeCdn;
      return props;
    },
  },
  watch: {
    url () {
      // Force reload of image, in case the URL changes
      let img = this.$el.querySelector('img');
      img.classList.add('js-lazyload');
    }
  },
  methods: {
    getFallbackImage (format) {
      if (typeof this.url !== 'undefined' && this.url.length) {
        return this.responsive ? this.$getSrcSet({ ...this.cleanedProps, format }) : this.$getSrcUrl({ ...this.cleanedProps, format })
      }
      return null
    }
  },
  head () {
    if (this.preload || !this.$store.getters['content/content/getIsFirstImagePreloaded']) {
      if (!this.preload) {
        this.$store.commit('content/content/setIsFirstImagePreloaded', true);
      }
      return {
        link: [
          {
            rel: 'preload',
            href: this.srcUrl,
            as: 'image',
            imagesrcset: this.srcSet
          },
        ],
      }
    }
  }
};
</script>