<template>
  <footer class="o-footer">
    <div class="container">
      <div
        v-if="footer && footer.columns && footer.columns.columns"
        class="row mb-5 mb-lg-0"
      >
        <div
          v-for="footerColumn in footer.columns.columns"
          :key="footerColumn.key"
          class="col-md-6 col-lg-3 mb-3 mb-md-7"
        >
          <accordion
            :hide-chevron-on-desktop="true"
            :title="footerColumn.headline"
          >
            <base-list>
              <base-list-item
                v-for="(footerColumnLink, i) in footerColumn.columnContent"
                :key="i"
              >
                <base-link
                  v-if="
                    footerColumnLink.ncContentTypeAlias === 'link' &&
                      $isNotNullOrUndefined(footerColumnLink.link)
                  "
                  class="o-footer__link py-1 my-1 d-inline-block"
                  :link="footerColumnLink.link.url"
                  :target="footerColumnLink.link.target"
                  :type="footerColumnLink.linkType"
                >
                  {{ footerColumnLink.link.name }}
                </base-link>

                <base-link
                  v-else-if="
                    footerColumnLink.ncContentTypeAlias === 'linkWithIcon' &&
                      $isNotNullOrUndefined(footerColumnLink.link)
                  "
                  class="o-footer__link py-1 my-1 d-inline-block"
                  :link="footerColumnLink.link.url"
                  :target="footerColumnLink.link.target"
                  :type="footerColumnLink.linkType"
                >
                  <base-icon
                    v-if="$isNotNullOrUndefined(footerColumnLink.icon)"
                    :icon="footerColumnLink.icon"
                    class="mr-3"
                  />
                  <span v-if="footerColumnLink.name !== '/'">{{
                    footerColumnLink.name
                  }}</span>
                </base-link>

                <base-link
                  v-else-if="
                    footerColumnLink.ncContentTypeAlias === 'linkWithImage' &&
                      $isNotNullOrUndefined(footerColumnLink.link)
                  "
                  class="o-footer__link py-1 my-1 d-inline-block"
                  :link="footerColumnLink.link.url"
                  :target="footerColumnLink.link.target"
                  :type="footerColumnLink.linkType"
                >
                  <base-image
                    v-if="footerColumnLink.image && footerColumnLink.image[0]"
                    :url="footerColumnLink.image[0].url"
                    image-class="w-auto"
                    :height="50"
                  />
                </base-link>
                <div
                  v-else-if="footerColumnLink.ncContentTypeAlias === 'addressPicker' && addresses && footerColumnLink.address && $isNotNullOrUndefined(getAddressById(footerColumnLink))"
                  class="py-1 my-1"
                >
                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).name)">
                    {{ getAddressById(footerColumnLink).name }}
                  </div>
                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).address1)">
                    {{ getAddressById(footerColumnLink).address1 }}
                  </div>
                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).address2)">
                    {{ getAddressById(footerColumnLink).address2 }}
                  </div>
                  <span v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).postalCode)">
                    {{ getAddressById(footerColumnLink).postalCode }}
                  </span>
                  <span v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).city)">
                    {{ getAddressById(footerColumnLink).city }}
                  </span>
                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).country)">
                    {{ getAddressById(footerColumnLink).country }}
                  </div>
                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).email)">
                    {{ getAddressById(footerColumnLink).email }}
                  </div>
                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).phone)">
                    {{ getAddressById(footerColumnLink).phone }}
                  </div>

                  <div v-if="$isNotNullOrUndefined(getAddressById(footerColumnLink).openingHours)" class="o-footer__hours" v-html="getAddressById(footerColumnLink).openingHours" />
                </div>
              </base-list-item>
            </base-list>
          </accordion>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-xl-3">
          <div class="m-icon-list">
            <div class="m-icon-list__headline h3 text-uppercase">
              {{ $t("FollowUs", "Følg os") }}
            </div>
            <ul v-if="footer && footer.additional" class="list-unstyled">
              <li
                v-for="(item, i) in footer.additional.socialMedia"
                :key="i"
                class="m-icon-list__item"
              >
                <component
                  :is="item.link ? 'base-link' : 'span'"
                  :link="item.link ? item.link.url : null"
                  class="m-icon-list__link"
                  :target="item.link ? item.link.target : null"
                >
                  <base-icon class="m-icon-list__icon" :icon="item.icon" />
                </component>
              </li>
            </ul>
          </div>
          <!--
          <currency-selector />
          -->
        </div>
        <div class="col-md-6 col-xl-3 order-xl-2">
          <div class="m-icon-list">
            <div class="m-icon-list__headline h3 text-uppercase">
              {{ $t("QualityGuarantee", "Din garanti for kvalitet") }}
            </div>
            <ul v-if="footer && footer.additional" class="list-unstyled">
              <li
                v-for="(item, i) in footer.additional.links"
                :key="i"
                class="m-icon-list__item mr-2"
              >
                <component
                  :is="item.link ? 'base-link' : 'span'"
                  :link="item.link ? item.link.url : null"
                  class=""
                  :target="item.link ? item.link.target : null"
                >
                  <base-image
                    class="m-icon-list__icon mr-1"
                    :url="item.image.length ? item.image[0].url : null"
                    :alt-text="item.imageAltText"
                    :width="70"
                    :height="60"
                    :responsive="false"
                  />
                </component>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-center d-flex align-items-end order-xl-1">
          <p class="o-footer__copyright">
            Copyright &copy; {{ copyrightYear }} by Brønnum
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon';
import BaseImage from '@/components/atoms/BaseImage';
import BaseLink from '@/components/atoms/BaseLink';
import BaseList from '@/components/molecules/BaseList';
import BaseListItem from '@/components/atoms/BaseListItem';
import Accordion from '@/components/molecules/Accordion';
// import CurrencySelector from '@/components/molecules/ShopCommerce/CurrencySelector';
import {
  mapMobileObserver,
  unMapMobileObserver,
} from '@/utils/mobile-observer';

export default {
  name: 'TheFooter',
  components: {
    BaseIcon,
    BaseImage,
    BaseLink,
    BaseList,
    BaseListItem,
    Accordion,
    // CurrencySelector
  },
  async fetch () {
    let linkList = this.footer?.columns?.columns?.map((x) => x.columnContent)?.flat();
    let addressList = linkList.filter(
      (x) => x.ncContentTypeAlias === 'addressPicker'
    );
    let addressGuids = addressList.map((x) => x.address?.meta?.id);
    await this.$store.dispatch('address/address/fetchAddress', {
      addressGuids,
      culture: this.culture,
    });
  },
  data () {
    return {
      open: [],
    };
  },
  computed: {
    ...mapMobileObserver(),
    footer () {
      return this.$store.getters['site/getFooter'];
    },
    culture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    copyrightYear () {
      return new Date().getFullYear();
    },
    addresses () {
      return this.$store.getters['address/address/getAddressList']
    }
  },

  watch: {
    isMobile: {
      handler (mobile) {
        this.$nextTick(() => {
          this.open = mobile
            ? []
            : this.footer?.columns?.columns.map((x) => x.headline);
        });
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    unMapMobileObserver();
  },
  methods: {
    getAddressById (footerColumnLink) {
      return this.addresses.find(address => address?.id === footerColumnLink?.address?.meta?.id)
    },
    toggle (payload) {
      if (!this.isMobile) return;
      if (this.open.includes(payload)) {
        this.open = this.open.filter((item) => item !== payload);
      } else {
        this.open.push(payload);
      }
    },
  },
};
</script>
