/* eslint-disable no-undef */
import Vue from 'vue';

const getCropUrl = (cdnUrl, args) => {
  let query = [];
  // If the url starts with http or https we treat it as a remote image from kontainer.com

  let url = args?.url;

  if (url?.startsWith('/bronnum')) {
    url = `/kontainer/${process.env.kontainerUrl}/${args.url}`
  }

  if (url.startsWith('http') || url.startsWith('https')) {
    url = `/kontainer/${args.url}`
  }

  let imageUrl = args.includeCdn && cdnUrl ? cdnUrl + url : url;
  let excludes = [
    'url',
    'furtherOptions',
    'includeCdn',
    'cropAlias'
  ];
  Object.keys(args).forEach(prop => {
    if (prop && args[prop] && !excludes.includes(prop)) {
      let param = '';
      param += prop;
      param += '=';
      param +=
        typeof args[prop] === 'object'
          ? Object.values(args[prop]).reverse().join(',')
          : args[prop].toString();
      query.push(param);
    }
  });
  let props = query.join('&');
  return `${imageUrl}?${props}${args.furtherOptions}`;
};

export default ({ app }) => {
  //Vue.use(app.store);

  Vue.prototype.$getSrcUrl = ({
    url,
    cropAlias,
    width,
    height,
    center = null,
    mode = 'crop',
    format = null,
    includeCdn = true,
    furtherOptions = ''
  },
  quality = 80) => {
    let cdnUrl = app.store.getters['site/getCdnUrl'];
    const args = {
      url,
      cropAlias,
      width: parseInt((cropAlias ? cropAlias.width : width)),
      height: parseInt((cropAlias ? cropAlias.height : height)),
      rxy: center ? `${center.left || 0},${center.top || 0}` : null,
      rmode: mode,
      format,
      quality: quality,
      includeCdn,
      furtherOptions
    };

    return getCropUrl(cdnUrl, args);
  };

  Vue.prototype.$getSrcSet = ({
    url,
    cropAlias,
    width,
    height,
    center = null,
    quality = 80,
    mode = 'crop',
    format = null,
    includeCdn = true,
    furtherOptions = ''
  }) => {
    let cdnUrl = app.store.getters['site/getCdnUrl'];
    const args = {
      url,
      cropAlias,
      width: parseInt(cropAlias ? cropAlias.width : width),
      height: parseInt(cropAlias ? cropAlias.height : height),
      rxy: center ? `${center.left || 0},${center.top || 0}` : null,
      rmode: mode,
      format,
      quality,
      includeCdn,
      furtherOptions
    };

    const ratio = args.height/args.width;

    const retinaArgs = JSON.parse(JSON.stringify(args));
    retinaArgs.width = parseInt(args.width * 2);
    retinaArgs.height = parseInt(args.height * 2);

    let retinaUrl = `, ${getCropUrl(cdnUrl, retinaArgs)} 2x`;

    const increments = [
      100,
      150,
      300,
      500,
      750,
      1000,
      1250,
      1500,
      1750,
      2000
    ]

    let sizeArgs = JSON.parse(JSON.stringify(args));
    let scrSets = increments.map(size => {
      sizeArgs.width = parseInt(size);
      sizeArgs.height = parseInt(size * ratio);
      let cropUrl = getCropUrl(cdnUrl, sizeArgs);
      return `${cropUrl} ${size}w`;
    })

    scrSets.join(',');
    scrSets += retinaUrl;

    return scrSets;
  };
};
