<template>
  <div v-if="summary" class="m-payment-information">
    <div v-if="!isVivaldiUser" class="d-flex justify-content-between">
      <p class="mb-2 text-xsmall">
        {{ $t('TotalBeforeVat', 'I alt (Inkl. rabat)') }}
      </p>
      <p class="font-weight-bold mb-2 text-xsmall">
        {{ summary.totalPriceExShippingExVatFormatted }}
      </p>
    </div>
    <div v-if="(!hasFreeShipping && isVivaldiUser) || !isVivaldiUser" class="d-flex justify-content-between">
      <p class="mb-2 text-xsmall">
        {{ $t('ShippingTotal', 'Fragt') }}
        <span v-if="showShippingDelta && (shippingFeeDelta > 0 && !isVivaldiUser)">
          ({{ $t('FreeShippingDeltaTextStart', 'Køb for') }}
          {{ shippingFeeDelta|formatCurrency(currency, culture) }}
          {{ $t('FreeShippingDeltaTextEnd', 'mere for at opnår fri fragt') }})
        </span>
      </p>
      <p class="mb-2 font-weight-bold text-xsmall">
        {{ summary.shippingPriceExVatFormatted }}
      </p>
    </div>
    <div v-if="summary.totalVat > 0" class="d-flex justify-content-between">
      <p class="mb-2 text-xsmall">
        {{ $t('VAT', 'Moms') }}
      </p>
      <p class="font-weight-bold mb-2 text-xsmall">
        {{ summary.totalVatFormatted }}
      </p>
    </div>
    <div v-if="summary.totalPrice > 0" class="d-flex justify-content-between mt-6 -total">
      <p class="text-large semibold mb-2">
        {{ $t('priceTotal','Total') }}
      </p>
      <p class="text-large semibold d-flex flex-column align-items-end mb-2">
        {{ summary.totalPriceFormatted }}
        <small class="text-xsmall">
          {{ $t('includingVat','Inkl. moms') }}
        </small>
      </p>
    </div>
    <div v-if="summary.totalDiscount>0" class="d-flex justify-content-between">
      <p class="mb-2 text-xsmall">
        {{ $t('Discount', 'Rabat') }}
      </p>
      <p class="font-weight-bold mb-2 text-xsmall color-red">
        -{{ summary.totalDiscountExVatFormatted }}
      </p>
    </div>
    <slot name="button">
      <base-link
        v-if="buttonLink"
        class="text-decoration-none"
        :link="buttonLink"
      >
        <base-button class="-primary w-100 my-6">
          {{ buttonText }}
        </base-button>
      </base-link>
    </slot>
  </div>
</template>

<script>
import LoginMixin from '@/mixins/loginMixin';
export default {
  name: 'PaymentInformation',
  mixins: [LoginMixin],
  props: {
    summary: {
      default: () => {},
      type: Object
    },
    buttonLink: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    showShippingDelta: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    culture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
    hasFreeShipping () {
      return this.summary.totalPriceExShippingExVat >= this.shippingLimit || this.summary.shippingTotal <= 0;
    },
    shippingLimit () {
      // For users not logged in 3000 DKK is min. price for before free shipping kicks in - not available in the endpoint, so hardcoded for now.
      // TODO: When going live with multiple currencies - we need to change the value depending on the currency
      let shippingLimit = this.isLoggedIn ? parseFloat(this.user?.accountProperties?.freightLimit) : 3000;
      return shippingLimit && !isNaN(shippingLimit) ? shippingLimit : 0;
    },
    shippingAmount () {
      let shippingAmount = parseFloat(this.user?.accountProperties?.freightAmount);
      return shippingAmount && !isNaN(shippingAmount) ? shippingAmount : 0;
    },
    shippingFeeDelta () {
      return this.shippingLimit - this.summary.totalPriceExShippingExVat;
    },
    isVivaldiUser () {
      return this.user?.accountProperties?.vivaldi === 1
    }
  }
}
</script>
