const resource = '/kontainer-api/bronnum';

export default (ctx, $axios) => ({
  async bySku (sku) {
    let skuEnding = `${sku.slice(-2)}`;

    try {
      const data = await $axios.$get(`${resource}/cache/json/${skuEnding}/${sku}.json`);
      return data;
    } catch (error) {
      console.error('Error on fetching assets:', error.message);
      return []; // Ensure Nuxt does not trigger a 500 error page
    }
  },

  async bySkus (skus) {
    try {
      const data = await $axios.$get(`${resource}/ws/?${getSkuQuery(skus)}`);
      return data;
    } catch (error) {
      console.error('Error on fetching assets:', error.message);
      return []; // Fallback value to prevent error page
    }
  }
});

const getSkuQuery = (skus) => {
  return skus.map(sku => `id[]=${sku}`).join('&');
};