<template>
  <a
    v-if="url && type"
    :href="url"
    rel="noopener"
    class="a-link"
    :title="title"
    :target="target"
  >
    <slot>...</slot>
  </a>
  <a
    v-else-if="url && linkIsExternal(url)"
    :href="url"
    rel="noopener"
    class="a-link"
    :title="title"
    :target="target"
  >
    <slot>...</slot>
  </a>
  <n-link
    v-else-if="url && !linkIsExternal(url)"
    :to="url"
    class="a-link"
    :title="title"
    :target="target"
  >
    <slot>...</slot>
  </n-link>
  <span v-else class="d-inline-block a-link">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    link: {
      requirred: true,
      type: String,
      default () {
        return '';
      }
    },
    target: {
      type: String,
      default: '_self'
    },
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  computed: {
    url () {
      let url = this.link;

      switch (this.type?.toLowerCase()) {
        case 'text':
          url = '';
          break;
        case 'email':
          url = 'mailto:' + this.link;
          break;
        case 'phone':
          url = 'tel:' + this.link;
          break;
        default:
          url = this.link;
          break;
      }

      return url;
    }
  },
  methods: {
    linkIsExternal (url) {
      return url.startsWith('https:') || url.startsWith('http:')
    }
  }
};
</script>
